body {
  margin: 0;
  background: #000000;
}

.header {
  text-align: center;
  margin-top: 20px;
}

.header img {
  width: 100px;
  height: 100px;
}

.container {
  min-height: 100%;
}

a {
  font-weight: 500;
  color: #f46e44;
}

.section {
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: rgba(0,0,0,.3);
  border: 2px solid hsla(0,0%,100%,.2);
  /* height: 310px; */
  max-width: 500px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 10;
}

.text {
  color: rgba(255, 255, 255, 0.3);
  font-style: italic;
  font-size: 12px;
  width: 70%
}

.colors {
  color: rgba(255, 255, 255, 0.3);
  font-size: 15px;
  font-weight: 500;
}

.section input {
  background: rgba(255, 255, 255, 0.4);
  mix-blend-mode: normal;
  border: 1px solid #C0C43F;
  box-sizing: border-box;
  backdrop-filter: blur(40px);
  border-radius: 15px;
  /* padding-left: 15px; */
}

.section .checkbox {
  accent-color: #C0C43F;
  width: 30px;
  height: 30px;
  background: #a19191 !important;
  border: 1px solid #C0C43F;
  box-sizing: border-box;
  border-radius: 7px;
  opacity: 1;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 500;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.6);
  opacity: 1; /* Firefox */
}

input::-ms-input-placeholder { /* Microsoft Edge */
  font-weight: 500;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.6);
  opacity: 1; /* Firefox */
}

.media-container {
  display: flex;
  justify-content: center;
}

.img-container > div {
  border: 1px dashed #C0C43F;
  border-radius: 20px !important;
  padding: 8px;
}

.img-container img {
  border-radius: 20px !important;
}

.media-container img {
  width: 100%;
}

@media (max-width: 767px) {
    .section .btn {
        width: 50%;
    }
}

@media (max-width: 541px) {
    .section .text-width {
       font-size: 11px !important;
       width: 100% !important;
    }
}

.fetch label {
  background: #C0C43F;
  mix-blend-mode: normal;
  border-radius: 15px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #000000;
}

.fetch button {
  background: #C0C43F;
  mix-blend-mode: normal;
  border-radius: 15px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #000000;
}

.generate button {
  background: #404041;
  mix-blend-mode: normal;
  border-radius: 15px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #717171;
}

.generate svg {
  color: #717171;
  fill: #717171;
}

.generate button.active {
  background: #f46e44;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #000000;
}

.generate button.active svg {
  fill: #000000;
  color: #000000;
}

.generate img {
   width: 23px;
   height: 23px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
